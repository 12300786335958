import axios from "axios";
import BaseHeader from "components/Header";
import CoreProvider from "core/hooks/useCore";
import { StoreProvider } from "core/hooks/useStore";
import fetcher from "core/utils/fetcher";
import { SessionProvider } from "next-auth/react";
import NextApp from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import "styles/custom.scss";
import useSWR, { SWRConfig } from "swr";

export const FEATURES = {
  ALLOW_SIGN_IN: true,
};

const CommandBar = dynamic(() => import("core/components/CommandBar"), { ssr: false });
const ToastProvider = dynamic(
  async () => {
    const { ToastProvider } = await import("@mojoactive/components");
    return ToastProvider;
  },
  { ssr: false }
);
const publicRoutes = new Set(["/auth/signin", "/auth/signup", "/auth/forbidden"]);

function App(props) {
  const { data: user } = useSWR("/api/auth", fetcher, { shouldRetryOnError: false });

  let defaultProps = { ...props, user: props.user || user };

  if (publicRoutes.has(props.router.pathname)) {
    return <AppContent {...defaultProps} />;
  }

  return <AppAuthenticated {...defaultProps} />;
}

App.getInitialProps = async (props) => {
  const appProps = await NextApp.getInitialProps(props);
  const { ctx } = props;
  let user = null;

  try {
    const { data } = await axios({
      method: "GET",
      url: `http://${ctx.req.headers.host}/api/auth`,
      withCredentials: true,
      headers: { cookie: ctx.req?.headers.cookie },
    });
    user = data;
  } catch (ex) {
    // do nothing
  }

  // server-side authentication checks
  let isLocalhost = ctx?.req?.headers?.["host"] === "localhost:3000";
  const queryString = ctx?.req?.url?.split("?")[1];
  const hasPayload = queryString?.includes("payload");
  const hasRedirected = queryString?.includes("redirected");
  const basePathname = ctx?.pathname?.split("?")[0];
  if (!user && process.env.LOCALHOST_STORE_HASH && isLocalhost && !hasRedirected) {
    // redirect to local callback if localhost
    ctx.res?.writeHead(302, { Location: "/api/callbacks/local?redirected=true" });
    ctx.res?.end();
  } else if (!user && !publicRoutes.has(basePathname) && FEATURES.ALLOW_SIGN_IN && !hasPayload && !hasRedirected) {
    // redirect to the login page if not authenticated
    ctx.res?.writeHead(302, { Location: "/auth/signin?redirected=true" });
    ctx.res?.end();
  } else if (!user && !publicRoutes.has(basePathname) && !FEATURES.ALLOW_SIGN_IN && !hasPayload && !hasRedirected) {
    // redirect to the home page if not authenticated
    ctx.res?.writeHead(302, { Location: "/auth/forbidden?redirected=true" });
    ctx.res?.end();
  }

  return { ...appProps, user };
};

export default App;

function AppContent({ Component, pageProps, ...rest }) {
  return (
    <>
      <Head>
        <title>Ryzome - MoJo Active</title>
      </Head>
      <SWRConfig value={{ fetcher, shouldRetryOnError: false }}>
        <ToastProvider config={{ position: "bottom-right" }}>
          <CoreProvider>
            <Component {...pageProps} {...rest} />
          </CoreProvider>
        </ToastProvider>
      </SWRConfig>
    </>
  );
}

function AppAuthenticated(props) {
  return (
    <SessionProvider session={props.session} refetchInterval={5 * 60}>
      <StoreProvider>
        <BaseHeader {...props} />
        <AppContent {...props} />
        <CommandBar {...props} />
      </StoreProvider>
    </SessionProvider>
  );
}
