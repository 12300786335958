export default function Logo({ className }) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 756 298.13" className={className}>
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n          .cls-1 {\n            fill: #1e2859;\n          }\n    \n          .cls-1, .cls-2, .cls-3 {\n            stroke-width: 0px;\n          }\n    \n          .cls-2 {\n            fill: #f97316;\n          }\n    \n          .cls-3 {\n            fill: #f97316;\n          }\n        ",
          }}
        />
      </defs>
      <path
        className="cls-1"
        d="M728.77,168.29c-12.3-12.3-32.25-12.3-44.55,0-3.9,3.9-6.56,8.58-7.99,13.53h-239.59s-51.11-51.11-51.11-51.11l-12.34,12.38,38.73,38.73h-154.68c-1.43-4.96-4.09-9.63-7.99-13.53-6.15-6.15-14.21-9.23-22.27-9.23s-16.12,3.08-22.28,9.23c-12.3,12.3-12.3,32.25,0,44.55,6.15,6.15,14.21,9.23,22.28,9.23s16.12-3.08,22.27-9.23c3.9-3.9,6.56-8.58,7.99-13.53h72.2l-28.2,28.2,12.36,12.36,40.56-40.56h30.22v57.62h17.48s15.56,0,15.56,0c1.43,4.96,4.09,9.63,7.99,13.53,12.3,12.3,32.25,12.3,44.55,0,12.3-12.3,12.3-32.25,0-44.55-12.3-12.3-32.25-12.3-44.55,0-3.9,3.9-6.56,8.58-7.99,13.53h-15.56v-40.14h101.51l40.56,40.56,12.36-12.36-28.2-28.2h148.14c1.43,4.96,4.09,9.63,7.99,13.53,12.3,12.3,32.25,12.3,44.55,0,12.3-12.3,12.3-32.25,0-44.55ZM237.8,201.4c-2.89,2.89-6.74,4.49-10.83,4.49s-7.94-1.59-10.83-4.49-4.49-6.74-4.49-10.83,1.59-7.94,4.49-10.83c2.89-2.89,6.74-4.49,10.83-4.49s7.94,1.59,10.83,4.49c2.89,2.89,4.49,6.74,4.49,10.83s-1.59,7.94-4.49,10.83Z"
      />
      <circle className="cls-2" cx="291.09" cy="248.63" r="31.5" />
      <path
        className="cls-2"
        d="M565.84,234.27c3.81,0,7.39,1.48,10.08,4.18,2.69,2.69,4.18,6.27,4.18,10.08s-1.48,7.39-4.18,10.08c-2.69,2.69-6.27,4.18-10.08,4.18s-7.39-1.48-10.08-4.18c-2.69-2.69-4.18-6.27-4.18-10.08s1.48-7.39,4.18-10.08c2.69-2.69,6.27-4.18,10.08-4.18M565.84,217.03c-8.06,0-16.12,3.08-22.27,9.23-12.3,12.3-12.3,32.25,0,44.55,6.15,6.15,14.21,9.23,22.27,9.23s16.12-3.08,22.28-9.23c12.3-12.3,12.3-32.25,0-44.55-6.15-6.15-14.21-9.23-22.28-9.23h0Z"
      />
      <g>
        <path
          className="cls-2"
          d="M19.29,145.47c-.86-.86-1.29-1.9-1.29-3.13V22.6c0-1.35.4-2.45,1.2-3.31.8-.86,1.87-1.29,3.22-1.29h47.64c14.96,0,26.7,3.49,35.22,10.48,8.52,6.99,12.78,16.92,12.78,29.8,0,8.95-2.18,16.46-6.53,22.53-4.35,6.07-10.39,10.46-18.12,13.15l26.86,46.9c.37.74.55,1.41.55,2.02,0,1.1-.4,2.02-1.2,2.76-.8.73-1.69,1.1-2.67,1.1h-16.74c-1.96,0-3.47-.43-4.51-1.29-1.04-.86-1.99-2.08-2.85-3.68l-23.73-43.59h-25.57v44.14c0,1.23-.4,2.27-1.2,3.13-.8.86-1.87,1.29-3.22,1.29h-16.74c-1.23,0-2.27-.43-3.13-1.29ZM69.5,77.41c7.36,0,12.9-1.66,16.65-4.97,3.74-3.31,5.61-8.09,5.61-14.35s-1.87-11.1-5.61-14.53c-3.74-3.43-9.29-5.15-16.65-5.15h-25.93v38.99h25.93Z"
        />
        <path
          className="cls-2"
          d="M157.2,182.33c-.74-.74-1.1-1.6-1.1-2.58,0-1.1.24-2.09.74-2.94l13.17-35.75-35.32-83.32c-.49-1.1-.74-1.96-.74-2.57.12-1.1.55-2.05,1.29-2.85.74-.8,1.66-1.2,2.76-1.2h14.9c2.33,0,3.98,1.17,4.97,3.49l24.46,60.15,25.2-60.15c.98-2.33,2.64-3.49,4.97-3.49h14.72c.98,0,1.87.37,2.67,1.1.8.74,1.2,1.6,1.2,2.57,0,.86-.25,1.84-.74,2.94l-50.88,122.2c-1.1,2.33-2.82,3.5-5.15,3.5h-14.53c-.98,0-1.84-.37-2.57-1.1Z"
        />
        <path
          className="cls-2"
          d="M247.07,145.47c-.86-.86-1.29-1.9-1.29-3.13v-10.48c0-1.72.86-3.68,2.57-5.89l47.09-55.73h-43.78c-1.23,0-2.27-.43-3.13-1.29-.86-.86-1.29-1.9-1.29-3.13v-10.3c0-1.22.43-2.27,1.29-3.13.86-.86,1.9-1.29,3.13-1.29h69.71c1.35,0,2.42.4,3.22,1.2.8.8,1.2,1.87,1.2,3.22v11.22c0,1.72-.86,3.56-2.58,5.52l-45.98,55.37h48.01c1.35,0,2.42.4,3.22,1.2.8.8,1.19,1.87,1.19,3.22v10.3c0,1.23-.4,2.27-1.19,3.13-.8.86-1.87,1.29-3.22,1.29h-75.05c-1.23,0-2.27-.43-3.13-1.29Z"
        />
        <path
          className="cls-2"
          d="M350.35,137.47c-7.85-7.42-12.08-17.57-12.69-30.44l-.18-8.09.18-8.09c.61-12.75,4.9-22.87,12.88-30.35,7.97-7.48,19.01-11.22,33.11-11.22s25.14,3.74,33.11,11.22c7.97,7.48,12.26,17.6,12.88,30.35.12,1.47.19,4.17.19,8.09s-.06,6.62-.19,8.09c-.61,12.88-4.84,23.02-12.69,30.44-7.85,7.42-18.95,11.13-33.29,11.13s-25.45-3.71-33.29-11.13ZM398.73,124.23c3.55-4.11,5.52-10.15,5.89-18.12.12-1.22.18-3.62.18-7.17s-.06-5.95-.18-7.17c-.37-7.97-2.33-14.01-5.89-18.12-3.56-4.11-8.58-6.16-15.08-6.16s-11.53,2.05-15.08,6.16c-3.56,4.11-5.52,10.15-5.89,18.12l-.19,7.17.19,7.17c.37,7.97,2.33,14.01,5.89,18.12,3.55,4.11,8.58,6.16,15.08,6.16s11.52-2.05,15.08-6.16Z"
        />
        <path
          className="cls-2"
          d="M446.34,145.47c-.86-.86-1.29-1.9-1.29-3.13V55.52c0-1.22.43-2.27,1.29-3.13.86-.86,1.9-1.29,3.13-1.29h14.35c1.23,0,2.27.43,3.13,1.29.86.86,1.29,1.9,1.29,3.13v6.25c6.37-8.34,15.27-12.51,26.67-12.51,13.61,0,23.11,5.46,28.51,16.37,2.94-4.9,7.11-8.86,12.51-11.86,5.39-3,11.22-4.51,17.47-4.51,10.05,0,18.27,3.43,24.65,10.3,6.37,6.87,9.56,16.86,9.56,29.98v52.79c0,1.23-.4,2.27-1.19,3.13-.8.86-1.87,1.29-3.22,1.29h-15.08c-1.23,0-2.27-.43-3.13-1.29-.86-.86-1.29-1.9-1.29-3.13v-51.32c0-7.73-1.63-13.34-4.87-16.83-3.25-3.49-7.57-5.24-12.97-5.24-4.78,0-8.86,1.78-12.23,5.33-3.37,3.56-5.06,9.14-5.06,16.74v51.32c0,1.23-.43,2.27-1.29,3.13-.86.86-1.9,1.29-3.13,1.29h-15.08c-1.23,0-2.27-.43-3.13-1.29-.86-.86-1.29-1.9-1.29-3.13v-51.32c0-7.73-1.69-13.34-5.06-16.83-3.37-3.49-7.63-5.24-12.78-5.24s-9.04,1.78-12.42,5.33c-3.37,3.56-5.06,9.14-5.06,16.74v51.32c0,1.23-.43,2.27-1.29,3.13-.86.86-1.9,1.29-3.13,1.29h-15.45c-1.23,0-2.27-.43-3.13-1.29Z"
        />
        <path
          className="cls-3"
          d="M616.25,136.73c-8.03-7.91-12.29-19.04-12.78-33.38l-.19-4.6.19-4.6c.61-13.98,4.93-24.95,12.97-32.92,8.03-7.97,18.79-11.96,32.28-11.96,14.47,0,25.66,4.41,33.57,13.24,7.91,8.83,11.86,20.6,11.86,35.32v3.86c0,1.23-.43,2.27-1.29,3.13-.86.86-1.96,1.29-3.31,1.29h-61.07v1.47c.24,6.75,2.12,12.42,5.61,17.01,3.5,4.6,8.31,6.9,14.44,6.9,7.23,0,13.06-2.82,17.47-8.46,1.1-1.35,1.99-2.21,2.67-2.57.67-.37,1.68-.55,3.04-.55h15.82c1.1,0,2.05.34,2.85,1.01.8.68,1.19,1.5,1.19,2.48,0,2.94-1.75,6.41-5.24,10.39-3.5,3.99-8.49,7.45-14.99,10.39-6.5,2.94-14.04,4.42-22.62,4.42-13.61,0-24.43-3.96-32.47-11.86ZM669.14,90.66v-.55c0-7.23-1.81-13.06-5.42-17.47-3.62-4.42-8.62-6.62-14.99-6.62s-11.34,2.21-14.9,6.62c-3.56,4.41-5.33,10.24-5.33,17.47v.55h40.65Z"
        />
      </g>
    </svg>
  );
}
