import Header from "core/components/Header";

// The main Header is preset in the core.
// You can pass additional links to the Header component via the `links` prop for custom tools.
// Example: <Header links={[{ text: "Link Text", href: "/link-path" }]} />
//
// If you want to hide links from the header menu, please use the Admin > Settings screen to toggle off the feature.

export default function BaseHeader() {
  return (
    <div>
      <Header
        links={[
          { text: "Ads", href: "/ads" },
          { text: "On-Demand Classes", href: "/class-tokens" },
          { text: "Customers", href: "/customers" },
          { text: "Settings", href: "/custom-settings" },
        ]}
      />
    </div>
  );
}
